


.fw-bolder {
  font-weight: 500 !important;
}
​ .table tr {
}
.thead,
tbody,
tfoot,
tr,
td,
th {
  border-style: hidden;
}
.table.table-row-dashed tr {
  border-bottom: 1px solid #cccccc;
}
​ .table tbody tr td,
.table thead tr th,
.table thead {
  border: none !important;
}
.table > :not(:first-child) {
  border-top: none !important;
}
.tag .min-w-100px {
  min-width: 31% !important;
}

.light-color {
  background-color: #F5EDED; /* Yellow warning color */
}

.table-scroll-task {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  height: 700px;
}

.table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  height: 350px;
}
.table-scroll table {
  width: 100%;

  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}
.table-wrap {
  position: relative;
}
.table-scroll th,
.table-scroll td {
  vertical-align: top;
}
.table-align .table-scroll th,
.table-align .table-scroll td {
  text-align: center;
}
.table-scroll thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: rgb(255, 255, 255);
}

.table-scroll tfoot,
.table-scroll tfoot th,
.table-scroll tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;

  z-index: 4;
  background-color: rgb(255, 255, 255);
}

th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
}
thead th:first-child,
tfoot th:first-child {
  z-index: 5;
}
.table-scroll tfoot tr {
  border: none;
}

.datePicker {
  z-index: 6;
  width: 200px;
}

.blink {
  animation: blink 2s steps(5, start) infinite;
  -webkit-animation: blink 1s steps(5, start) infinite;
}
@keyframes blink {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}
.form-row{
  width: 100%;
  display: flex;
}
@media (min-width: 991px) and (max-width: 2000px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1730px;
  }
}

overall-links {
  background: blue;
}

.read-more-less--more,
.read-more-less--less {
  color: #999;
}
.links {
  color: crimson;
}

.link {
  color: crimson;
}
.link:hover {
  color: #00f;
}

.h-500px {
  height: 406px !important;
}

.view-more-button {
  color: '#001689';
  background-color: '#001689';
  font-size: 14px;
  padding: '5px';
  cursor: 'pointer';
}

.viewMoreLink {
  color: '#001689';
  font-size: '15px';
  padding: '5px';
  margin: '5px';
  text-decoration: 'underline';
}

.vl {
  border-left: 6px solid rgb(0, 0, 0);
  height: 500px;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 0;
}

/*----------------------------------view more button ----------------------*/

@media only screen and (max-width: 600px) {
  /* body {
    background-color: lightblue;
  } */
  scss
  .header-custom {
    font-size: 0.75rem !important;
  }
  .header-custom-2 {
    /* font-size: 0.4rem !important; */
    content: '\a';
    white-space: pre;
  }
  .button-po {
    margin-bottom: 0.1rem !important;
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
}
.flex-container span {
  margin-right: 10px;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.external-td {
  padding-top: '0.5rem ' !important;
  padding-bottom: '0.5rem' !important;
}

.pinkText {
  color: 'rgb(138, 38, 80)';
}

.quantity::-webkit-inner-spin-button,
.quantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bg-color-custome1
{
  background-color: #e5dada9d;
}

.example {
  margin-left: 50%;
  padding: 50%;
  background: rgba(0, 0, 0, 0.05); 
   margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  z-index: 1031;
}

.spin_example {
  margin: 30px 0;
  margin-bottom: 20px;
  padding: 20% 50%;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position:absolute;
}


.table-scroll
{
  display: block;
  overflow: auto;


}
.table-scroll::-webkit-scrollbar {
  width: 14px;
  height: 14px;  

  
}

.table-responsive::-webkit-scrollbar{
  width: 14px;
  height: 14px;  
 
}

 /* .table-color-1
{
  background: #d3dad6 !important;
} */



.ant-table-body {
  min-height: 500px;
}
#outer
{
    width:100%;
    text-align: center;
}
.inner
{
    display: inline-block;
}

.time-table-row-select > .ant-spin-nested-loading > .ant-spin-container > div > .ant-table-content > .ant-table-body > table > tbody > tr:hover > td {
  background-color: unset !important;
}

.dont-show .ant-select-selection-item {
  display:block !important;
  max-height: 40px;
  overflow: hidden;
}

.dont-show .ant-select-selector{
  max-height: 40px;
  overflow: hidden;

}

.ant-radio-button-wrapper-checked{
  color:black !important;
  border-color: #D0D5DD !important;
  background: #D0D5DD !important;
}

 .custom-input .ant-input-number-input::-webkit-input-placeholder {
  /* Your placeholder styles here */
  font-style: italic;
  font-weight: bolder !important;
  color:black;
 
}

.custom-input .ant-input-number-input::-moz-placeholder {
  /* Your placeholder styles here */
  font-style: italic;
  font-weight: bolder !important;
  color:black;
 
}
.custom-input .ant-input-number-input:-ms-input-placeholder {
  /* Your placeholder styles here */
  font-style: italic;
  font-weight: bolder !important;
  color:black;
 
}

.container-iframe {
  position: relative;
  width: 100%;
  padding-top: 50%;
}

.container-iframe > iframe { 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#five_day_table span {
  width: 14px;
  height: 14px;
  display: block;
  float: left;
  margin: 1px 3px 0px 0px;
}
.r-cl span
{
 background:  Blue; 
}
.m-cl span
{
 background:  red; 
}
.cm-cl span
{
 background:  green; 
}
.d-cl span
{
 background:  brown; 
}